import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post/post"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, pages },
  location
}) => {

  const posts = [...data?.allWpPost?.nodes]

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" location={location} />
        <Bio />
        <div className="container">
          <p>
            No blog posts found. Add posts to your WordPress site and they'll
            appear here!
          </p>
        </div>
      </Layout>
    )
  }

  const heroPost = posts.shift();
  return (
    <Layout isHomePage>
      <Seo title="All posts" location={location} />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xxl-8 offset-xxl-2">
              <Post post={heroPost} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xxl-8 offset-xxl-2">
              <div className="post-line"></div>
              <h1 className="blog-title">All articles</h1>
              <div className="row">
                {posts.map((post, index) => {
                  return (
                    <div className="col-12 col-md-6" key={post.uri}>
                      <Post post={post} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex justify-content-center mt-5" style={{ gap: '10px' }}>
            {previousPagePath && <Link className="btn btn-sm btn-outline-dark" to={previousPagePath} title={"Previous page"}>&lt;</Link>}
            {pages?.length > 1 && pages.map((p) => {
              if (p.number === currentPage) {
                return <b className="btn btn-sm disabled" title={p.number} key={`blog-page-${p.number}`}>{p.number}</b>
              } else {
                return <Link className="btn btn-sm btn-outline-dark" to={p.link} title={p.number} key={`blog-page-${p.number}`}>{p.number}</Link>
              }
            })}
            {nextPagePath && <Link className="btn btn-sm btn-outline-dark" to={nextPagePath} title={"Next page"}>&gt;</Link>}
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: {fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
